{
  "common": {
    "help": "help",
    "sure": "sure",
    "cancel": "cancel",
    "skip": "skip",
    "submit": "submit",
    "agree": "agree",
    "refresh": "refresh",
    "newki": "newki",
    "searchGoods": "searchGoods",
    "addCar": "加入购物车",
    "buyNow": "立即购买",
    "vip": "品友",
    "newVip": "新品友",
    "apply": "开通",
    "check": "查看",
    "default": "默认",
    "save": "保存",
    "save&use": "保存并使用",
    "delete": "删除",
    "confirm": "請確認",
    "finish": "完成",
    "backHome": "回到首页",
    "checkOrder": "查看订单",
    "edit": "編輯",
    "remove": "移除",
    "pullRefresh": "下拉即可刷新內容~",
    "pullLoosing": "松開即可刷新內容~",
    "loading": "加載中……",
    "noMore": "沒有更多啦",
    "copy": "複製",
    "copySuccess": "已複製至剪切板",
    "copyError": "複製失敗",
    "rule": "規則",
    "changeSuccess": "修改成功",
    "selectGoodsEmpty": "请先选择商品",
    "select": "選擇",
    "recommend": "For You",
    "close": "關閉",
    "wechatAuthError": "微信授权失败",
    "vipPrice": "品友價",
    "login": "登入",
    "total": "總計",
    "remark": "備註",
    "siteUnavailable": "當前站點不可用",
    "retryLater": "出錯了，請稍候重試~"
  },
  "login": {
    "welcome": "welcome to NewkiLand",
    "inputPhoneNumber": "請輸入手機號",
    "inputRightPhoneNumber": "請輸入正確的手機號",
    "inputSmsCode": "請輸入6位手機驗證碼",
    "getSmsCode": "獲取驗證碼",
    "seconds": "s",
    "login": "立即登入/註冊",
    "loginNow": "立即登入",
    "loginPwd": "密碼登入",
    "loginSms": "驗證碼登入/註冊",
    "loginTip": "未註冊時驗證碼登入後將自動創建新奇站帳號",
    "ServiceAgreement": "《服務協議》",
    "PrivacyAgreement": "《隱私協議》",
    "loginAgreement": "登入即代表同意",
    "agreementTip": "请阅读并勾选同意",
    "logging": "logging",
    "smsError": "驗證碼錯誤，請重新輸入~",
    "setPwd": "設置密碼",
    "inputPwd": "請輸入密碼",
    "findPwd": "找回密碼",
    "graphicCode": "請輸入圖形驗證碼",
    "graphicRightCode": "请输入正确的图形验证码",
    "setPwdAgain": "請再次確認密碼",
    "setPwdError": "兩次密碼不一致，請重新輸入",
    "pwdVerify": "請輸入8-20位密碼，含字母、數字",
    "pwdError": "密碼錯誤，請重新輸入~",
    "smsExpire": "驗證碼已失效，請重新獲取",
    "chooseAreaCode": "請選擇手機區號",
    "chooseLanguage": "請選擇語言",
    "chooseSite": "請選擇要訪問的站點",
    "finishPicVerify": "向右拖動完成拼圖",
    "SecurityVerification": "安全驗證",
    "error:": "login error",
    "loginFloat": "登入开启新奇购物",
    "loginAgain": "修改成功，請重新登錄！",
    "thirdLogin": "第三方登入",
    "bind": "綁定手機",
    "bind-tip": "請綁定您當前使用的手機，以便新奇站能提供更優質的品友服務。",
    "cancelRegister": "取消註冊"
  },
  "site": {
    "selectSite": "Site",
    "selectLanguage": "Language",
    "selectSiteFirst": "Please Selected Site First",
    "areaCodeError": "該站點未設置區號代碼，請聯繫客服！"
  },
  "goods": {
    "spec": "spec",
    "change": "change",
    "discountInfo": "共{num}件，优惠价：",
    "pkg": "精选套装",
    "book": "立即预约",
    "booked": "已预约",
    "offState": "商品已下架",
    "offStorage": "地区无货,请切换地址",
    "buyCount": "购买数量",
    "limitCount": "限购{num}件",
    "orderWay": {
      "online": "送貨上門",
      "onlineDesc": "支持物流送貨上門，下單時填寫收貨信息即可。",
      "take": "自提點取貨",
      "takeDesc": "1、支持順豐自提點取貨。\n2、下單時填寫自提點信息即可。\n3、由於自提點有大小限制，是否可以使用，需在下單時計算商品總體驗後，才決定能否使用。",
      "offLine": "門市自取",
      "offLineDesc": "1、支持到門市取貨。\n2、下單時選擇門市自取即可。\n3、門市："
    },
    "weight": "重量：{weight}kg",
    "volume": "體積：{volume}cm³",
    "bindBuy": "请先添加数量~",
    "error": "該商品不存在~"
  },
  "promotion": {
    "exclusivePrice": "专属价",
    "price": "当前购买价",
    "originPrice": "原价",
    "vipTip": "开通品友即可享受",
    "promotionTip": "限时优惠",
    "pkgName": "推荐套装",
    "start": "距离开始",
    "end": "距离结束",
    "reduce": "立减",
    "discount": "优惠",
    "bind": "一起买，更优惠",
    "changeTitle": "換購",
    "countChange": "满{num}件换购",
    "countChangeDetail": "购买本商品<span class='discount-text'>满{num}件</span>，可<span class='discount-text'>换购{count}件<span>",
    "changeDetail": "购买本商品，可<span class='discount-text'>换购{count}件<span>",
    "countGift": "满{num}件赠券",
    "countGiftDetail": "購買本商品<span class='discount-text'>滿{num}件</span>，支付訂單後系統自動發放以下優惠券",
    "coupon": "赠券",
    "couponDetail": "購買本商品，支付訂單後系統自動發放以下優惠券",
    "priceGift": "满{num}赠券",
    "priceGiftDetail": "購買本商品<span class='discount-text'>滿{num}</span>，支付訂單後系統自動發放以下優惠券",
    "priceChange": "满{num}换购",
    "priceChangeDetail": "订单<span class='discount-text'>满{num}</span>，可<span class='discount-text'>换购{count}件</span>",
    "change": "去换购",
    "allDiscount": "满${num}享{count}折",
    "allDiscountDetail": "购买<span class='discount-text'>满{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "allReduce": "满${num}减{count}",
    "allReduceDetail": "购买<span class='discount-text'>满{num}</span>，可立减<span class='discount-text'>{count}</span>",
    "bindQtyDiscount": "滿{num}件{count}折",
    "bindQtyDiscountDetail": "購買本商品<span class='discount-text'>滿{num}件</span>，每件商品可享<span class='discount-text'>{count}折</span>",
    "bindQtyReduce": "滿{num}件每件減{count}",
    "bindQtyReduceDetail": "購買本商品<span class='discount-text'>滿{num}件</span>，每件商品可立減<span class='discount-text'>{count}</span>",
    "bindPriceDiscount": "滿{num}享{count}折",
    "bindPriceDiscountDetail": "購買本商品<span class='discount-text'>滿{num}</span>，可享<span class='discount-text'>{count}折</span>",
    "bindPriceReduce": "滿{num}件減{count}",
    "bindPriceReduceDetail": "購買本商品<span class='discount-text'>滿{num}</span>，可立減<span class='discount-text'>{count}</span>",
    "singleDiscount": "每件享{count}折",
    "singleDiscountDetail": "購買本商品每件可享<span class='discount-text'>{count}折</span>",
    "singleReduce": "每件減{count}",
    "singleReduceDetail": "購買本商品每件立減<span class='discount-text'>{count}</span>",
    "changeSelect": "超值换购-可选",
    "order": {
      "amount": "商品金額",
      "vipDiscount": "品友折扣",
      "vipBenefit": "品友優惠",
      "freightDiscount": "運費優惠",
      "discount": "促銷優惠",
      "vipCoupon": "品友券",
      "coupon": "優惠券",
      "couponText": "券",
      "freightCoupon": "運費券",
      "reduce": "隨機立減",
      "buyAmount": "應付款",
      "reduceText": "减",
      "freight": "運",
      "changeDiscount": "惠",
      "original": "原訂單抵扣",
      "changeText": "换"
    },
    "moreDiscount": "獲取更優價",
    "morePromotion": "更多優惠",
    "changeGoods": "優惠換購品",
    "giftError": "已超過可選換購品最大數量，請重新選擇~",
    "changeGoodsNotSelectYet": "您有優惠換購品未選擇~"
  },
  "coupon": {
    "discount": "<span class='coupon-discount'>{num}</span>折",
    "reduce": "减<span class='coupon-discount'>{num}</span>",
    "all": "全場通用",
    "special": "指定商品可用",
    "vip": "专属券",
    "freightName": "運費券",
    "limit": "满{num}可用",
    "freight": "用于订单运费抵扣",
    "use": "立即使用",
    "receive": "立即领取",
    "gift": "赠{num}张",
    "allDate": "永久有效",
    "dynamicDate": "{num}天後過期",
    "noCoupon": "无可用券",
    "hasCoupon": "有{num}张可用，请选择",
    "modalTitle": "新奇优惠券",
    "able": "可用优惠券",
    "disable": "不适用优惠券",
    "coupon": "优惠券",
    "exchange": "立即兌換",
    "noUse": "暂无可用",
    "member": "品友券",
    "exchangeCoupon": "品禮券",
    "exchangeCouponA": "品禮兌換券"
  },
  "search": {
    "search": "搜索",
    "normal": "综合",
    "count": "销量",
    "new": "上新",
    "category": "品类",
    "history": "搜索历史",
    "hot": "热门搜索",
    "urlError": "跳转地址不符合URL规则"
  },
  "address": {
    "distribution": "送至",
    "listTitle": "地址管理",
    "add": "新增地址",
    "edit": "编辑地址",
    "receiver": "收货人",
    "receiverValid": "请输入收货人信息",
    "phone": "手机号码",
    "phoneValid": "请输入手机号码",
    "areInfo": "所在地区",
    "areInfoValid": "请选择所在地区",
    "address": "详细地址",
    "addressValid": "请输入详细地址",
    "setDefault": "设为默认",
    "delete": "確認刪除該地址？",
    "deleteSuccess": "刪除成功",
    "saveSuccess": "添加成功",
    "errorUrl": "无效的地址",
    "exceed": "地址超出配送範圍，請修改地址！"
  },
  "number": {
    "thousand": "thousand",
    "tenThousand": "tenThousand",
    "hundredMillion": "hundredMillion"
  },
  "track": {
    "track": "track",
    "list": "Track List",
    "trackInfo": "Track Info.",
    "listEmpty": "Track Empty",
    "order": "已下单",
    "orderMeta": "商品已下单",
    "stock": "商品备货中",
    "stockMeta": "商品备货出库中",
    "package": "已发货",
    "packageMeta": "商品已经打包，等待揽收",
    "done": "已完成",
    "doneMeta": "商品已经签收，欢迎下次观临",
    "transit": "运输中",
    "pickup": "派送中",
    "delivered": "已签收",
    "waitTake": "待交收",
    "takeDone": "已交收"
  },
  "order": {
    "order": "訂單",
    "list": "訂單列表",
    "tabs": {
      "all": "全部",
      "payment": "待支付",
      "progress": "待處理",
      "done": "待交收",
      "sharing": "待分享"
    },
    "status": {
      "status": "訂單狀態",
      "cancel": "已取消",
      "payment": "待支付",
      "paySure": "支付確認中",
      "payError": "支付異常",
      "payNotAll": "部分付款",
      "handle": "門店備貨中",
      "storageHandle": "倉庫備貨中",
      "waitTake": "待取貨",
      "waitReceive": "待收貨",
      "finish": "已完成"
    },
    "payError": "檢測到支付異常，訂單未完成支付~",
    "paySure": "您的支付信息正在確認中，請耐心等待~",
    "payNotAll": "訂單僅部份付款，請及時支付尾款~",
    "btnGroup": {
      "pay": "支付",
      "payAgain": "重新支付",
      "payResidue": "支付尾款",
      "payContinue": "繼續支付",
      "addCart": "加入購物車",
      "checkTrack": "查看物流",
      "sure": "確認收貨",
      "chat": "聯繫客服",
      "share": "分享體驗",
      "shareContinue": "繼續分享",
      "afterSale": "申請售後",
      "cancelOrder": "取消訂單",
      "afterSaleSearch": "售後查詢",
      "sureDialog": "確認已經收到訂單商品？",
      "instruction": "使用說明",
      "afterSaleQty": "已退/換<span>{num}</span>件"
    },
    "cancel": {
      "title": "取消原因",
      "sureAgain": "請再次確認是否取消當前訂單？"
    },
    "error": "訂單不存在~"
  },
  "orderDetail": {
    "statusDesc": {
      "cancel": "您的訂單已取消\n如有支付款項將於1~3個工作日退回\n歡迎下次光臨~",
      "finish": "您的訂單已完成，歡迎下次光臨~",
      "handle": "您的訂單正在備貨中\n請耐心等待~",
      "payNotAll": "您的轉賬金額少於訂單應付金額！\n請及時支付尾款~",
      "payError": "您的轉賬信息有誤，請重新支付~",
      "paySureOnline": "支付到賬確認中\n請耐心等候~",
      "paySureOffline": "您的轉賬信息核對中，\n預計1~3個工作日完成~\n如上傳憑證信息有誤 ，請及時修改~",
      "payment": "超時後訂單將自動取消\n已取消訂單無法恢復，請及時支付~"
    },
    "delivery": {
      "onLine": "送貨上門",
      "offLine": "門市自取",
      "takeWay": "自提點取貨"
    },
    "order": {
      "no": "訂單號",
      "createTime": "下單時間",
      "payTime": "支付時間",
      "payWay": "支付方式",
      "amount": "商品金額",
      "freight": "運費",
      "paid": "實付款",
      "shouldPaid": "應付款"
    },
    "storeOrder": {
      "no": "訂單號",
      "sourceBillNo": "來源訂單號",
      "createTime": "購買時間",
      "standardAmount": "商品金額",
      "discountAmount": "優惠金額",
      "payAmount": "實付金額"
    },
    "showMore": "查看更多",
    "payProof": "支付憑證",
    "morePkg": "訂單分多個包裹發出",
    "takeCode": "取货码",
    "takeTip": "請於 {time} 前往指定門市取貨~",
    "remark": "订单留言",
    "vip": {
      "name": "新奇站品友",
      "disable": "已失效",
      "able": "待生效",
      "time": "有效期至：{time}"
    },
    "vipName": "品友",
    "integral": "獲得積分"
  },
  "buy": {
    "title": "確認訂單",
    "addAddress": "請添加地址！",
    "selectAddress": "选择地址",
    "selectShop": "選擇門市",
    "shop": "門市",
    "similar": "相似訂單未支付，並且已使用優惠券！",
    "similarJump": "操作",
    "shopEmpty": "請選擇取貨門店！",
    "remark": "订单留言，最多100字",
    "submit": "提交订单",
    "total": "合计",
    "discount": "已优惠",
    "explain": "价格说明",
    "site": {
      "infoTip": "信息提示",
      "infoDesc": "當前登入站點與訂單提供站點信息不一致，請確認是否繼續下單或切換站點~",
      "goon": "繼續下單",
      "change": "切換到{name}",
      "chat": "聯繫客服",
      "languageError": "该站点未配置语言，切换失败"
    },
    "vip": {
      "able": "本單開通品友已省",
      "disable": "開通品友，本單預計可省",
      "check": "品友專屬權益待生效~"
    },
    "deliveryTypeError": "獲取交收方式有誤",
    "addressIdError": "請填寫交收信息",
    "exceedError": "地址超出配送範圍，請修改地址"
  },
  "sendWay": {
    "1": "送貨上門",
    "2": "門市自取",
    "3": "自提點取貨"
  },
  "pay": {
    "title": "收银台",
    "vipPrice": "包含品友购买  {price}/年",
    "pay": "支付",
    "scan": "掃碼支付",
    "app": "請使用支付APP掃碼支付",
    "paying": "支付中",
    "cancel": "订单已取消",
    "promotionTime": "促銷優惠，剩餘支付時間",
    "payTime": "剩餘支付時間",
    "levelMessage": "家D好品僅差一步！\n確認不繼續嗎？",
    "waitPay": "稍後支付",
    "payGoing": "繼續支付",
    "payConfirm": "1、已支付請點擊【我已支付】，並耐心等待支付結果，請勿重複支付！\n2、如需更換支付方式，請點擊【去支付】",
    "goPay": "去支付",
    "payDone": "我已支付",
    "success": {
      "finish": "支付完成",
      "search": "支付中...",
      "sendCoupon": "新奇小蓝给您送券啦~",
      "sendCouponTip": "订单确认收货后系统自动确认",
      "searchTip": "1、由於網絡原因，當前支付結果未能及時獲得\n2、如確認已經支付，請耐心等待或聯繫客服\n3、如您未支付，請點擊重新支付",
      "backApp": "返回「新奇站」APP",
      "downloadApp": "下載「新奇站」APP",
      "backTip": "如返回失敗，請手動打開新奇站APP",
      "searchAppTip": "1、由於網絡原因，當前支付結果未能及時獲得\n2、請返回「新奇站」APP等待支付結果"
    },
    "amount": "金额",
    "error": "支付異常，請選擇其他支付方式！"
  },
  "cart": {
    "cart": "购物车",
    "invalid": "失效商品",
    "otherSite": "其它站点商品",
    "selectAll": "全选",
    "change": "换购",
    "changeText": "优惠换购品，请选择",
    "amount": "合计",
    "discount": "已优惠",
    "priceExplain": "价格说明",
    "buy": "结算",
    "collect": "收藏",
    "addCollect": "加入收藏",
    "removeGoods": "移除商品",
    "gift": "换购",
    "empty": "购物车没有商品哦~",
    "addSuccess": "添加成功！",
    "confirmDelete": "確定刪除這些商品嗎？",
    "limit": "限購：{num}",
    "inventory": "庫存：{num}",
    "cancelCollect": "取消收藏",
    "sureRemove": "確認移除該列表商品嗎？",
    "sureCancelCollect": "確認取消收藏選中商品嗎？",
    "success": "加入購物車成功"
  },
  "nav": {
    "home": "首頁",
    "category": "品類",
    "newki": "家品曆",
    "shop": "購物",
    "mine": "我的"
  },
  "applyVip": {
    "apply": "開通品友更優惠",
    "discount": "本單以省",
    "forecast": "本單預計可省",
    "desc": "全站會員價",
    "desc1": "(非會員商品除外)",
    "agree": "本人已閱讀並同意",
    "agreement": "《新奇站增值會員用戶協議》",
    "couponNum": "品友券X{num}",
    "goodsExchangeNum": "品礼券X{num}",
    "freightNum": "运费券X{num}",
    "exchangeNum": "品禮券X{num}",
    "memberNum": "品友券X{num}",
    "monthNum": "每月{num}张",
    "special": "會員專屬",
    "noLimit": "無門檻",
    "hasLimit": "指定商品可用",
    "activity": "VIP活動",
    "gift": "奇趣禮品",
    "more": "更多驚喜",
    "noDiscount": "無專屬優惠"
  },
  "transfer": {
    "tip1": "1、成功過數後，請點擊頁面下方【上傳過數憑證】按鈕，上傳過數成功的圖片資料。",
    "tip2": "2、Newki將在一個工作日進行資料核對，核對完成後為你提供商品的寄送服務。",
    "transferInfo": "轉賬信息",
    "hide": "收起",
    "show": "查看",
    "payImage": "提交成功過數截圖",
    "uploadPayImage": "上傳過數憑證",
    "changePayWay": "變更支付方式",
    "chat": "聯繫客服",
    "payTeaching": "付款教程",
    "submit": "確認提交",
    "outTradeNoTip": "請複製以下內容至轉賬頁面備註處。"
  },
  "fileUpload": {
    "sizeError": "請上傳小於{size}M的文件",
    "typeError": "請上傳{type}類型的文件",
    "numError": "請上傳少於{num}個的文件",
    "uploading": "上傳中...",
    "failed": "上傳失敗"
  },
  "update": {
    "pwd": {
      "title": "修改密碼",
      "updateBySms": "通過手機驗證碼修改",
      "updateByPwd": "通過舊密碼修改",
      "submit": "確認修改",
      "cancel": "取消修改",
      "oldPwd": "請輸入當前登入密碼"
    },
    "phone": "修改手機號碼",
    "nickname": "修改暱稱",
    "nicknameLabel": "暱稱",
    "nicknamePlaceholder": "請輸入暱稱",
    "email": "修改郵箱",
    "emailLabel": "郵箱",
    "emailPlaceholder": "請輸入您的郵箱地址",
    "emailErrorTips": "請輸入正確的郵箱地址"
  },
  "setting": {
    "title": "設置",
    "info": "個人信息",
    "address": "收貨地址管理",
    "pwd": "修改密碼",
    "newki": "關於新奇站",
    "registerAgreement": "用戶註冊協議",
    "agreement": "隱私政策",
    "logout": "登出"
  },
  "history": {
    "title": "瀏覽記錄",
    "empty": "暫無瀏覽記錄",
    "addCart": "加入購物車",
    "addCollect": "加入收藏"
  },
  "integral": {
    "title": "我的積分",
    "canUse": "可用積分",
    "total": "總積分",
    "frozen": "凍結積分",
    "frozenExplain": "正常交易後獲得的積分，會有7天的凍結期，之後會變更為可用積分進行使用",
    "empty": "暂无积分使用明细~",
    "normal":"正常交易",
    "marketingActivity": "营销活动",
    "integralActivity": "积分活动",
    "integralOverDue": "积分过期",
    "integralOffset": "积分抵现",
    "cancelOffset": "取消抵现",
    "manualAdjustment": "人工調整",
    "integralClear": "積分清零",
    "integralExchange": "积分兑换",
    "integralLock": "积分锁定",
    "integralUnlock": "积分释放"
  },
  "coupons": {
    "title": "優惠券",
    "notUse": "待使用",
    "used": "已使用",
    "failed": "已過期",
    "empty": "該分類沒有優惠券~"
  },
  "my": {
    "notLogin": "未登入",
    "registerDay": "新奇體驗已<span class='blue-text'>{day}</span>天",
    "all": "全部",
    "more": "更多",
    "order": {
      "title": "我的訂單",
      "titleTabs": {
        "site": "官網訂單",
        "shop": "門店訂單"
      },
      "notPay": "待付款",
      "deal": "待處理",
      "makeSure": "待交收",
      "waitShare": "待分享",
      "afterSale": "售後"
    },
    "history": "瀏覽記錄",
    "share": "分享",
    "myShare": "我的分享",
    "myCollect": "我的收藏",
    "view": "近期瀏覽",
    "integral": "積分",
    "coupon": "優惠券",
    "collectEmpty": "你還未收藏任何內容喔,<br/>快<span class='blue-text'>去逛逛</span>吧~",
    "shareEmpty": "你還未發佈任何內容喔,<br/>快<span class='blue-text'>去分享</span>吧~",
    "viewEmpty": "你還未瀏覽過新奇商品喔,<br/>快<span class='blue-text'>去逛逛</span>吧~"
  },
  "vipPackage": {
    "discount": "累積已省",
    "integral": "我的積分",
    "doubleIntegral": "雙倍積分",
    "integralDiscount": "積分優惠",
    "present": "奇趣禮品",
    "more": "更多驚喜",
    "gift": "品友禮物",
    "active": "專屬活動",
    "month": "每月",
    "week": "每周",
    "year": "每年",
    "day": "每日",
    "coupon": "品友券",
    "freight": "運費券",
    "goodsExchange": "品禮券",
    "couponDes": "专属优惠券",
    "freightDes": "运费抵扣券",
    "goodsExchangeDes": "品礼兑换券",
    "vipPrice": "全站會員價",
    "send": "贈送",
    "unit": "張",
    "onlyVip": "僅會員商品",
    "discountReduce": "低至8.5折",
    "noLimit": "無門檻",
    "exclusive": "專屬",
    "member": "品友券"
  },
  "error": {
    "pageNotFount": "當前頁面不存在",
    "pageJump": "頁面將在",
    "pageJump1": "秒後自動跳轉"
  },
  "share": {
    "title": "體驗分享",
    "share": "分享體驗",
    "shareTip": "歡迎寫下您的新奇體驗…",
    "shareGoods": "體驗過的新奇商品",
    "select": "請選擇",
    "myShare": "我的分享",
    "myShareEmpty": "暂时没有分享哦~",
    "myLike": "我的点赞",
    "myLikeEmpty": "暂时没有点赞哦~",
    "textEmpty": "請填寫分享的內容~",
    "imageEmpty": "請上傳分享的圖片~"
  },
  "userInfo": {
    "title": "個人信息",
    "phone": "手機號碼",
    "nickname": "暱稱",
    "email": "郵箱"
  },
  "emailPopup": {
    "title": "請填寫郵箱用於賬單服務",
    "agreement": "《支付服務說明》",
    "errorTips": "請輸入正確的郵箱地址"
  },
  "sharePopup": {
    "title": "立即分享給好友",
    "link": "複製鏈接"
  },
  "collect": {
    "success": "Success",
    "deleSuccess": "Success"
  },
  "newki": {
    "entry": "Enter",
    "month": "月",
    "monthly": "每月家D新品味?!",
    "monthlyYet": "今月你家佐未?!",
    "monthlyAdd": "家D",
    "fit": "宜"
  },
  "afterSale": {
    "status": "状态",
    "list": "售後列表",
    "history": "售后记录",
    "detail": "售後服務詳情",
    "detailApply": "售後服務申請"
  },
  "article": {
    "error": "未傳入正確的文章ID或使用場景！",
    "empty": "該文章暫無內容~"
  },
  "activity": {
    "fail": "活動已過期",
    "end": "活動已結束",
    "integral": "當前積分",
    "num": "可換次數",
    "change": "立即兌換",
    "join": "立即參與",
    "record": "兌換記錄",
    "recordEmpty": "暫無兌換記錄~",
    "invite": "邀請記錄",
    "inviteEmpty": "暫無邀請記錄，快分享給好友吧~",
    "rule": "活動規則",
    "memberDay": "可體驗會員權益{num}個月",
    "price": "價值",
    "view": "去查看",
    "congratulations": "恭喜用戶",
    "receive": "獲得",
    "joined": "您已參與過該活動~"
  },
  "chat": {
    "sendOrder": "發送訂單",
    "goods": "發送商品",
    "searchOrder": "查詢訂單",
    "title": "新奇站正在為您服務",
    "sendTip": "說點什麼",
    "noMoreMsg": "--沒有更多消息--",
    "loading": "加載中...",
    "error": "消息獲取失敗！",
    "sendEmpty": "發送消息不能為空~",
    "addAsk": "添加咨詢",
    "loginAsk": "登入咨詢",
    "newkiAsk": "新奇小藍咨詢",
    "navTitle": "很高興為您服務，每月家D新品未!?"
  },
  "render": {
    "title": "內容組件多語言",
    "button": {
      "buyNow": "立即開通 <span class='buy-now'>{price}</span> /年",
      "tips": "限時優惠",
      "agree": "本人已閱讀並同意",
      "articleTitle": "《新奇站增值會員用戶協議》",
      "chat": "聯繫客服",
      "goPay": "繼續支付"
    },
    "promotion": {
      "buyVipNow": "支付到賬確認中<br/>如上傳憑證信息有誤 ，請及時修改~",
      "partialPayment": "您的轉賬金額少於訂單應付金額！<br/>請及時支付尾款~",
      "billError": "您的轉賬信息有誤，請重新支付~",
      "inEffect": "品友權益生效中~",
      "exist": "您有訂單加購了品友，完成支付後可享有權益"
    }
  }
}
